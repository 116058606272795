<template>
  <el-dialog
    class="beginner-guidance Dialog_style1"
    :visible.sync="visible"
    :close-on-click-modal="false"
  >
    <div slot="title" class="mod-title ov-h">
      <span>新手引导</span>
    </div>
    <div class="beginner-guidance-content">
      <div>
        <h3>创建您的舆情监测方案</h3>
        <p class="cor-blue">什么是监测方案？</p>
        <p>
          监测方案是您关注的主题相关的关键词组合。系统会将包含这些关键词的舆情信息推送给您。
        </p>
        <div class="flex space-between mar-t-45">
          <img
            src="@/assets/images/common/jisu.svg"
            alt="极速模式创建"
            width="320"
            height="190"
            @click="createPlan('js')"
          />
          <img
            src="@/assets/images/common/zhuanjia.svg"
            alt="专家模式创建"
            width="320"
            height="190"
            @click="createPlan('zj')"
          />
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
export default {
  name: 'beginner-guidance',
  data() {
    return {
      visible: false
    };
  },
  watch: {
    visible: function(v) {
      if (!v) {
        this.$store.dispatch('userInfo');
      }
    }
  },
  methods: {
    createPlan(type) {
      this.$emit('createPlan', type);
      this.visible = false;
    },
    show() {
      this.visible = true;
    }
  }
};
</script>
<style scoped>
.beginner-guidance >>> .el-dialog {
  width: 800px;
  height: 518px; /*background: url(../../assets/images/1.png) 0% 0% / 800px 518px no-repeat;*/
}
.beginner-guidance >>> .el-dialog__close {
  width: 60px;
  text-align: center;
  padding: 0;
  height: 25px;
  line-height: 25px;
  font-size: 12px;
  border-radius: 18px;
  border: 1px solid rgba(221, 223, 230, 1);
}
.beginner-guidance >>> .el-icon-close:before {
  content: '跳过';
}
.beginner-guidance-content {
  text-align: center;
  padding: 0 70px;
  border-top: 1px solid #f2f2f2;
}
.beginner-guidance h3 {
  font-size: 16px;
  line-height: 60px;
  padding-top: 10px;
}
.beginner-guidance .cor-blue {
  font-size: 14px;
  font-weight: 600;
}
.beginner-guidance p {
  line-height: 32px;
  margin-block-start: 0;
  margin-block-end: 0;
  font-size: 12px;
}
.beginner-guidance img {
  cursor: pointer;
}
</style>
